<template>
  <div class="troubleshooting_guide">
    <component
      :is="componentName(element)"
      v-for="element in page.elements"
      :key="element.id"
      :element="element"
      :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    />
  </div>
</template>

<script>
  import { AlchemyPage } from "~/mixins/alchemyVue"
  import { useNotificationStore } from "~/pinia/notifications"
  const NARROW_ELEMENTS = ["text_block", "headline"]

  export default {
    components: {
      disqus_embed: defineAsyncComponent(
        () => import("~/alchemy/elements/disqus_embed"),
      ),
      divider: defineAsyncComponent(() => import("~/components/Divider")),
      headline: defineAsyncComponent(
        () => import("~/alchemy/elements/headline"),
      ),
      image_full_width: defineAsyncComponent(
        () => import("~/alchemy/elements/image_full_width"),
      ),
      text_block: defineAsyncComponent(
        () => import("~/alchemy/elements/text_block"),
      ),
      troubleshooting_overview: defineAsyncComponent(
        () => import("~/alchemy/elements/troubleshooting_overview"),
      ),
      troubleshooting_solution: defineAsyncComponent(
        () => import("~/alchemy/elements/troubleshooting_solution"),
      ),
    },
    mixins: [AlchemyPage],
    setup(props) {
      useHead(usePageInfo(props.page))
      const notificationStore = useNotificationStore()
      onMounted(() => {
        notificationStore.announce(props.page.title)
      })
    },
    data() {
      return {
        NARROW_ELEMENTS,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
